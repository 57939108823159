import { createStore } from "vuex";

export default createStore({
  state: {
    username: "",
    yaziID: "",
  },
  mutations: {
    usernameMutations(state, value) {
      state.username = value;
    },

    yaziIDMutations(state, value) {
      state.yaziID = value;
    },
  },
  actions: {},
  modules: {},
});
