// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore/lite";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDN9nsDQUGPsWY39SPIF2v0vEBDBahy1H8",
  authDomain: "fordizayn-b99fd.firebaseapp.com",
  databaseURL: "https://fordizayn-b99fd-default-rtdb.firebaseio.com",
  projectId: "fordizayn-b99fd",
  storageBucket: "fordizayn-b99fd.appspot.com",
  messagingSenderId: "903373524891",
  appId: "1:903373524891:web:88c474f902ac3e907d1b3e",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth();
const storage = getStorage(app);

export { db, auth, storage, analytics, app };
