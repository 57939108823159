<template>
  <div>
    <div id="calismalarim-alani" class="calismalarim-alani">
      <div class="container">

        <div class="row">
          <div class="section-baslik">WEB PROJELERİM</div>

          <div class="baslik-line"></div>
        </div>
        <br />
        <div class="row">
          <div
            class="col-md-3 col-12 float-left"
            v-for="item in projectList"
            :key="item"
          >
            <div class="card">
            
              
              <img
                class="card-img-top"
                :src="item.resim"
                alt="Card image cap"
              />
              <div class="title-box">
                <img
                class="card-small"
                :src="item.resim"
                alt="Card image cap"
              />
              <h5 class="">{{ item.sira }}</h5>
                <h5 class="">{{ item.baslik }}</h5>
                <span class="">{{ item.aciklama }}</span>
                <span class="">{{ item.icerik }}</span>
              </div>
        
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      projectList: {},
      allList: {},
    };
  },
  created() {
    this.getProjects();
  },

  methods: {
    getProjects() {
      axios
        .get("https://fordizayn-b99fd-default-rtdb.firebaseio.com/yazilar.json")
        .then((response) => {
          this.allList = response.data;
          this.allList = Object.values(this.allList);
          this.projectList = this.allList.filter(
            (item) => item.kategoriID === "3c63e1e0-8a48-11ec-bab1-9fe25a89643f"
          );

          this.projectList.sort(function (a, b) {
            return parseInt(a.sira) > parseInt(b.sira)? 1 : -1;
          });
        });
    },
  },
};
</script>

<style scoped>
.card-small{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 7px;
  border:1px solid white;
  padding: 1px;
}
.model{
  background-color: rgba(218, 220, 225,.4);
  width: 100%;
  
  position: absolute;
  height: 100vh;
}
.title-box{
  margin-top: -50px;
  display: none;
  position: absolute;
  z-index: 9;
  background-color: rgba(59, 61, 61, 0.979);
  width: 105%;
  margin-left: -2%;
  min-height: 160%;
  transition: .8 all;
  border:1px solid white;
  border-bottom: 2px solid white;
  padding-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 2px -1px, rgba(0, 0, 0, 0.02) 0px 2px 4px -1px;
}
.title-box h5{
  position: relative;
  z-index: 9;
  color: orange;
  font-size: 15px;
  margin-left: auto;
  display: block;
  width: 100%;
  padding: 5px;
 
  text-align: center;
  margin-top: 10px;
  transition: .8s all;

}
.title-box span{
  position: relative;
  z-index: 9;
  color: white;

  font-size: 13px;
  margin-left: auto;
  display: block;
  width: 100%;
  padding: 11px;

  text-align: center;
  margin-top: -7px;
  transition: .8s all;

}
.card-aciklama{

  color: rgb(39, 38, 38);
  font-size: 12px;


}
.card-img-top{
  height: 180px;
  object-fit: cover;
}
.card:hover .title-box{
display: block;


}
.card:hover .card-title{
  background-color: rgba(199, 22, 22, 0.95);
margin-top: 80px;
}
.card {
  transition: .4s;
  margin: 30px;
  width: 100%;
  margin-top: 50px;
  transition: all 0.5s;
  background-color: rgba(255, 255, 255, 0.95);
}
.card:hover {

  cursor: pointer;
}
.section-baslik {
  font-size: 20px;
  font-weight: 600;
  background-color: #ffd31d;
  padding: 7px 20px;
  box-shadow: 1px 1px 5px #888888;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  margin-top: 30px;
  width: 30%;
  line-height: 30px;
  text-align: center;
}

.calismalarim-alani {
  background-image: url("../assets/images/wall5.png");
  width: 100%;
  height: auto;
  padding: 30px 5px;
  padding-bottom: 50px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.calismalarim-box {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 80px;
  border-bottom: 1px solid rgba(224, 224, 224, 0.1);
  padding: 1px;
}
.img {
  position: relative;
}
.img img {
  width: 100%;
  height: 220px;
  object-fit: cover;
}
.info {
  background-color: white;
  position: relative;
  width: 100%;
  height: 300px;
}

.baslik {
  color: rgb(197, 127, 47);
  font-size: 20px;
  font-weight: 600;
  padding-left: 10px;
  background-color: white;
}
.aciklama {
  color: rgb(236, 232, 232);
  font-size: 16px;
  margin-top: 10px;
  padding-left: 10px;
}
</style>
