<template>
  <div v-if="hakkimdaInfo.resim">
 

    <div  id="hakkimda-alani" class="hakkimda-alani">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-12 float-left">
            <div class="hakkimda-img-section">
              <img class="hakkimda-img" :src="hakkimdaInfo.resim" />
              <div class="img-box">Frontend Developer</div>
            </div>
          </div>

          <div class="col-md-7 col-12 float-left">
            <div class="hakkimda-icerik">
              <p class="baslik1">Hakkimda</p>

              <p class="baslik2">{{ hakkimdaInfo.baslik }}</p>

              <p class="aciklama">
                {{ hakkimdaInfo.icerik }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script> 
import axios from "axios";
export default {
  data() {
    return {
      hakkimdaInfo: [],
    };
  },
  created() {
    this.getHeaderInfo();
  },
  methods: {
    getHeaderInfo() {
      axios
        .get("https://fordizayn-b99fd-default-rtdb.firebaseio.com/yazilar.json")
        .then((response) => {
          this.hakkimdaInfo = response.data;
        
          this.hakkimdaInfo = Object.values(this.hakkimdaInfo);
          
          this.hakkimdaInfo = this.hakkimdaInfo.find(
            (item) => item.yaziId === "-MxyEXP2CzyJLe3aCDs4"
          );
     
        });
    },
  },
};
</script>

<style scoped>
.baslik1 {
  color: orange;
  font-size: 20px;
  position: relative;
}
.baslik1::before {
  content: "";
  position: absolute;
  background-color: white;
  width: 40px;
  height: 2px;
  top: 40px;
  left: 0px;
}
.baslik2 {
  font-size: 35px;
  text-transform: uppercase;
  font-weight: 700;
}
.baslik3 {
  font-size: 25px;
}
.aciklama {
  font-size: 16px;
  color: #9b9b9b;
}
.hakkimda-icerik {
  padding: 30px;
  margin-left: 50px;
  color: white;
}
.hakkimda-alani {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #343436;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.hakkimda-img-section {
  position: relative;
}
.hakkimda-img {
  width: 90%;
  height: 550px;
  object-fit: cover;
  border: 5px solid rgb(214, 207, 207);
  padding: 5px;
}
.img-box {
  position: absolute;
  top: 225px;
  right: -50px;
  width: 150px;
  height: 150px;
  padding: 12px;
  border: 10px solid white;
  background-color: #d4820b;
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 25px;
}

@media (max-width: 500px) {
  .hakkimda-icerik {
    padding: 10px;
    margin-left: 10px;
  }
  .baslik2 {
    font-size: 25px;
  }

  .baslik3 {
    font-size: 18px;
  }

  .hakkimda-img {
    width: 80%;
    height: 550px;
  }
  .img-box {
    top: 225px;
    right: -10px;
    width: 150px;
    height: 150px;
  }
}
</style>
