<template>
  <div>
    <div id="yetkinliklerim-alani" class="yetkinliklerim-alani">
      <div class="container">
        <div class="row">
          <div class="section-baslik">YETKİNLİKLERİM</div>
          <br />
          <!-- <div class="baslik-line"></div> -->
          <div class="col-md-12">
            <div
              class="col-md-6 col-sm-6 col-12 float-left"
              v-for="item in skillList"
              :key="item"
            >
              <div class="skill-box">
                <div class="point-box">
                  <p>{{ item.baslik }}</p>
                  <p class="point">{{ item.aciklama }}</p>
                </div>
                <span class="point-line">
                  <div></div>
                  <p :style="{ width: item.aciklama + '%' }"></p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      point: "40",
      skillName: "JavaScript",
      pageList: {},
      skillList: {},
    };
  },
  created() {
    this.getSkills();
  },
  methods: {
    getSkills() {
      axios
        .get("https://fordizayn-b99fd-default-rtdb.firebaseio.com/yazilar.json")
        .then((response) => {
          this.pageList = response.data;
          this.pageList = Object.values(this.pageList);
          this.skillList = this.pageList.filter(
            (item) => item.kategoriID === "8f0299e0-0794-11ed-b681-05df9d001da5"
          );

          this.skillList.sort(function (a, b) {
            return a.aciklama > b.aciklama ? -1 : 1;
          });
        });
    },
  },
};
</script>

<style scoped>
.section-baslik {
  font-size: 20px;
  font-weight: 600;
  background-color: #ffd31d;
  padding: 7px 20px;
  box-shadow: 1px 1px 5px #888888;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  margin-top: 5px;
  width: 30%;
  line-height: 30px;
  text-align: center;
}
.row {
  position: relative;
}
.yetkinliklerim-alani {
  width: 100%;
  height: auto;
  padding: 10px 5px;
  padding-bottom: 50px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #2f2f31;
  padding-top: 100px;
  padding-bottom: 100px;
}

.skill-box {
  width: 95%;
  margin-top: 1px;
  padding: 10px 30px;
  position: relative;
}
.point-line {
  position: relative;
}
.point-line div {
  width: 100%;
  background-color: white;
  height: 12px;
  border-radius: 5px;
  z-index: 1;
  box-shadow: 1px 1px 1px #888888;
}
.point-line p {
  background-color: rgb(197, 127, 47);
  height: 12px;
  border-radius: 5px;
  z-index: 2;
  margin-top: -12px;
}
.point-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: -10px;
}
.point-box p {
  color: white;
}
</style>
