<template>
  <div>
    <HEADER></HEADER>
    <HAKKIMDA></HAKKIMDA> 
    <YETKINLIKLERIM></YETKINLIKLERIM>
    <CALISMALARIM></CALISMALARIM>
    <GITHUBREPO></GITHUBREPO>
    <CONTACT></CONTACT>
    <FOOTER></FOOTER>
  </div>
</template>

<script>
import HEADER from "../pages/header.vue";
import HAKKIMDA from "../pages/hakkimda.vue";
import YETKINLIKLERIM from "../pages/yetkinliklerim.vue";
import CALISMALARIM from "../pages/calismalarim.vue";
import FOOTER from "../pages/footer.vue";
import GITHUBREPO from "../pages/gitHubRepository.vue";
import CONTACT from "../pages/contact.vue";

export default {
  name: "App",
  components: {
    HEADER,
    HAKKIMDA,
    YETKINLIKLERIM,
    CALISMALARIM,
    CONTACT,
    GITHUBREPO,
    FOOTER,
  },
};
</script>

<style></style>
