<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModalCenter"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">! Uyarı</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          BU kaydı silmek istediğinizden emin misiniz?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Vazgeç
          </button>
          <button
            @click="kategoriSil"
            type="button"
            data-dismiss="modal"
            class="btn btn-primary"
          >
            Sil
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="home">
    <div class="header">
      <p>Yazılar</p>
    </div>
    <div class="content">
      <div class="card">
        <p>Tüm Yazılar</p>

        <div class="filter-section">
          <div class="col-md-3 float-left">
            <div class="form-group">
              {{ selectedKategoryID.uid }}
              <div class="select-box">
                <select
                  @change="filter($event)"
                  v-model="selectedKategory"
                  class="form-control"
                  id="exampleFormControlSelect1"
                >
                  <option value="" disabled selected>Kategori Seçiniz</option>
                  <option
                    @change="kategory - selected"
                    v-for="item in kategoriler"
                    :key="item.uid"
                  >
                    {{ item.kategoriAdi }}
                  </option>
                </select>

                <a class="btn btn-sm btn-info text-white">Listele</a>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Resim</th>
                <th scope="col">Baslık</th>
                <th scope="col">Tarih</th>
                <th scope="col">Yayın Duruumu</th>
                <th scope="col">Sıra</th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in gelenYaziListesi" :key="key">
                <th scope="row">
                  <img class="yazi-resim" :src="item.resim" />
                </th>
                <td>{{ item.baslik }}</td>
                <td>{{ item.tarih }}</td>
                <td>{{ item.yayinDurumu }}</td>
                <td>{{ item.sira }}</td>
                <td
                  @click="yaziDuzenle(item.yaziId)"
                  class="btn btn-duzenle btn-sm btn-success"
                >
                  Düzenle
                </td>
                <td
                  class="btn btn-sil btn-sm btn-danger"
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                  @click="kategoriSilOnay(key)"
                >
                  Sil
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      filteredYaziList: [],
      kategoriler: [],
      selectedKategoryID: "",
      gelenYaziListesi: [],
      yaziListesi: [],
      silinecekKategoriID: "",
    };
  },
  created() {
    this.yaziListesiGetir();

    this.kategoriListesiGetir();
  },
  methods: {
    filter(event) {
      this.gelenYaziListesi = this.yaziListesi;
      var kategoriName = event.target.value;

      this.selectedKategoryID = this.kategoriler.find(
        (item) => item.kategoriAdi === kategoriName
      );

      var selectedID = this.selectedKategoryID.uid;
      console.log("kategori ID:", selectedID);
      this.gelenYaziListesi = Object.values(this.gelenYaziListesi);
      console.log("Yazı Listesi:", this.gelenYaziListesi);

      var newlist = this.gelenYaziListesi.filter((element) => {
        return element.kategoriID.match(selectedID);
      });
      console.log("BUlunan:", newlist);
      this.gelenYaziListesi = newlist;

      this.gelenYaziListesi.sort(function (a, b) {
            return parseInt(a.sira) > parseInt(b.sira)? 1 : -1;
          });
    },

    kategorySelected() {
      alert();
    },

    kategoriListesiGetir() {
      axios
        .get(
          "https://fordizayn-b99fd-default-rtdb.firebaseio.com/yazi_kategoriler.json"
        )
        .then((response) => {
          this.kategoriler = response.data;
          this.kategoriler = Object.values(this.kategoriler);
        });
    },

    yaziDuzenle(id) {
      this.$router.push(`/controlPanel/yazi_duzenle/${id}/`);
    },
    //Kategori Sil Onay Penceresi
    kategoriSilOnay(id) {
      console.log("silinen ıd", id);
      this.silinecekKategoriID = id;
    },

    //Kategori Sil
    kategoriSil() {
      axios
        .delete(
          "https://fordizayn-b99fd-default-rtdb.firebaseio.com/yazilar/" +
            this.silinecekKategoriID +
            ".json"
        )
        .then(() => {
          console.log("Silindi");
          this.yaziListesiGetir();
        });
    },

    yaziListesiGetir() {
      axios
        .get("https://fordizayn-b99fd-default-rtdb.firebaseio.com/yazilar.json")
        .then((response) => {
          this.yaziListesi = response.data;
          this.gelenYaziListesi = response.data;
          // this.gelenYaziListesi = Object.values(this.gelenYaziListesi);

          
          this.gelenYaziListesi.sort(function (a, b) {
            return parseInt(a.sira) > parseInt(b.sira)? 1 : -1;
          });

        });
    },
  },
};
</script>

<style scoped>
.select-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.select-box select {
  margin-top: 15px;
  margin-right: 20px;
}

.yazi-resim {
  width: 80px;
  height: 60px;
  object-fit: contain;
  box-shadow: 1px 1px 5px #888888;
}
.home {
  background-color: #f4f6fb;
  margin-left: -30px !important;
  width: calc(100% + 45px) !important;
}
.header {
  padding: 25px;
  height: 10px;
}
.header p {
  font-size: 24px;
}
.content {
  margin-top: 25px;
  height: 100vh;
}
.card {
  margin: 20px;
}
.card p {
  padding: 20px;
}
.btn {
  margin-right: 15px;
  height: 33px;
  margin-top: 20px;
  padding-top: 5px;
  cursor: pointer;
}
</style>
