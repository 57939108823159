<template>
  <div class="container-fluid">
    <div class="col-md-3 float-left">
      <div class="siteyi-onizle-alani">
        <i class="fas fa-wifi"></i>
        <a target="_blank" href="/home">Siteyi Önizle</a>
      </div>
    </div>
    <div class="col-md-9 float-left profil-section">
      <div class="profile">
        <img src="../assets/images/logo4.png" class="user" />
        <p>{{ username }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    username() {
      return this.$store.state.username;
    },
  },
};
</script>

<style scoped>
.siteyi-onizle-alani {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
}
.siteyi-onizle-alani i {
  padding-top: 10px;
  margin-right: 9px;
}
.siteyi-onizle-alani a {
  padding-top: 6px;
  color: gray;
}
.container-fluid {
  height: 100px;
  padding: 20px;
  font-family: "Nunito", sans-serif;
  border-bottom: 1px solid rgba(26, 22, 22, 0.2);
  margin: 0 !important;
  font-size: 14px;
  color: #66686e;
  background-color: rgba(237, 243, 237, 0.1);

  margin-left: -30px !important;
  width: calc(100% + 45px) !important;
}

.user {
  border-radius: 50%;
  width: 50px;
  object-fit: cover;
  height: 50px;
  border: 1px solid rgb(48, 35, 35);
}
.profil-section {
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.profile img {
  margin-right: 10px;
}
.profile p {
  margin-top: 10px;
}
</style>
