<template>
  <div>
    <div class="home">
      <div class="header">
        <p>Control Panel Anasayfa</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.home {
  background-color: #eff2f7;
  margin-left: -30px !important;
  width: calc(100% + 45px) !important;
}
.header {
  padding: 15px;
  height: 50px;
}
</style>
