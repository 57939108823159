<template>
  <div>
    <div id="github-alani" class="calismalarim-alani">
      <div class="container">
        <div class="row">
          <div class="section-baslik">Bazı Github Repolarım</div>

          <div class="baslik-line"></div>
        </div>
        <br />
        <div class="row">
          <div
            class="col-md-6 col-12 float-left"
            v-for="item in projectList"
            :key="item"
          >
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">{{ item.baslik }}</h5>
                <!-- <p class="card-text">{{ item.icerik.slice(0, 50) }}...</p> -->
                <p class="languages">Html-Css-Vue JS-Firebase</p>
                <a :href="item.aciklama" class="btn btn-primary">Details</a>
              </div>
            </div>
          </div>
        </div>
        <a class="github-link" href="https://github.com/ahmetbto"><span>Github Hesabım</span></a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      projectList: {},
      allList: {},
    };
  },
  created() {
    this.getProjects();
  },

  methods: {
    getProjects() {
      axios
        .get("https://fordizayn-b99fd-default-rtdb.firebaseio.com/yazilar.json")
        .then((response) => {
          this.allList = response.data;
          this.allList = Object.values(this.allList);
          this.projectList = this.allList.filter(
            (item) => item.kategoriID === "450c35f0-0b2f-11ed-bbe2-e957265a19fb"
          );

          this.projectList.sort(function (a, b) {
            return a.sira > b.aciklama ? -1 : 1;
          });
        });
    },
  },
};
</script>

<style scoped>
.github-link{
  text-align: center;
  width: 100%;
color: rgb(248, 247, 246);
  margin-left: auto;
  position: relative;
  display: block;
  font-size: 18px;
  margin-top: 20px;
}
.github-link span{
 background-color: #093251;
 padding: 5px 15px;
 border-radius: 4px;
}
.languages {
  margin-top: 10px;
  background-color: #97cac6;
  padding: 5px 15px;
  font-size: 12px;
  display: block;
  width: 190px;
  border-radius: 3px;
}
.card {
  margin: 30px;
  width: 100%;
  transition: all 0.5s;
  background-color: rgba(255, 255, 255, 0.95);
}
.card:hover {
  margin-top: 26px;
  cursor: pointer;
}
.section-baslik {
  font-size: 20px;
  font-weight: 600;
  background-color: #ffd31d;
  padding: 7px 20px;
  box-shadow: 1px 1px 5px #888888;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  margin-top: 30px;
  width: 30%;
  line-height: 30px;
  text-align: center;
}

.calismalarim-alani {
  width: 100%;
  height: auto;
  padding: 30px 5px;
  padding-bottom: 50px;
}
.calismalarim-box {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 80px;
  border-bottom: 1px solid rgba(224, 224, 224, 0.1);
  padding: 1px;
}
.img {
  position: relative;
}
.img img {
  width: 100%;
  height: 220px;
  object-fit: cover;
}
.info {
  background-color: white;
  position: relative;
  width: 100%;
  height: 300px;
}

.baslik {
  color: rgb(197, 127, 47);
  font-size: 20px;
  font-weight: 600;
  padding-left: 10px;
  background-color: white;
}
.aciklama {
  color: rgb(236, 232, 232);
  font-size: 16px;
  margin-top: 10px;
  padding-left: 10px;
}
</style>
