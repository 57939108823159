<template>
  <div class="home">
    <div class="header">
      <p>Yazı Düzenle</p>
    </div>
    <div class="content">
      <div class="card">
        <div class="card-body">
          <div class="col-md-12 float-left">
            <div class="col-md-6 float-left">
              <div class="form-group">
                <label for="exampleFormControlInput1">Kategori</label>

                <select
                  v-model="selectedKategory"
                  class="form-control"
                  id="exampleFormControlSelect1"
                >
                  <option value="" selected>
                    {{ gelenKategoriAdi.kategoriAdi }}
                  </option>

                  <option v-for="item in kategoriler" :key="item.uid">
                    {{ item.kategoriAdi }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="exampleFormControlSelect1">Başlık</label>
                <input
                  v-model="yeniYazi.baslik"
                  type="email"
                  class="form-control"
                  id="exampleFormControlInput1"
                />
              </div>

              <div class="form-group">
                <label for="exampleFormControlSelect1">Açıklama</label>
                <input
                  v-model="yeniYazi.aciklama"
                  type="email"
                  class="form-control"
                  id="exampleFormControlInput1"
                />
              </div>
            </div>
            <div class="col-md-3 float-left">
              <div class="form-group">
                <label for="exampleFormControlInput1">Sıra</label>
                <input
                  v-model="yeniYazi.sira"
                  type="email"
                  class="form-control"
                  id="exampleFormControlInput1"
                />
              </div>
              <div class="form-group">
                <div class="resim-yukleme-alani">
                  <label for="exampleFormControlSelect1">Resim</label>
                  <img :src="yeniYazi.resim" class="rounded" alt="..." />

                  <input
                    ref="imageInput"
                    @change="onImagePicked"
                    type="file"
                    style="display: none"
                    class="btn btn-primary btn-sm btn-resim-yukle"
                    accept="image/*"
                  />

                  <button
                    v-if="selectImage"
                    @click.prevent="chooseImage"
                    type="button"
                    class="btn btn-primary btn-sm btn-resim-yukle"
                  >
                    Resim Seç
                  </button>

                  <button
                    @click.prevent="removeImageFunc"
                    type="button"
                    v-if="removeImage"
                    class="btn btn-primary btn-sm btn-resim-yukle"
                  >
                    Resim Sil
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-9 float-left">
            <div class="form-group">
              <label for="exampleFormControlTextarea1">İçerik</label>
              <textarea
                v-model="yeniYazi.icerik"
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="5"
              ></textarea>
              <br />
              <div :style="alert" class="" v-if="isnotification" role="alert">
                {{ notification }}
              </div>

              <button
                @click="yaziDuzenle"
                type="button"
                class="btn btn-success btn btn-resim-yukle"
              >
                Güncelle
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storage } from "../boot/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { uuid } from "vue-uuid";
import axios from "axios";
export default {
  data() {
    return {
      gelenKategoriAdi: "",
      gelenKategoriID: "",
      gelenID: "",
      gelenYaziID: "",
      gelenYazi: [],
      selectImage: true,
      removeImage: false,
      alert: this.alertLight,
      alertRed: "color: red;",
      alertLight: "color: green;",
      notification: "",
      isnotification: false,
      selectedKategory: "",
      kategoriler: [],
      gelenYaziListesi: [],

      yeniYazi: {
        yaziId: uuid.v1(),
        tarih: "",
        kategoriID: "",
        baslik: "",
        aciklama: "",
        icerik: "",
        sira: "1",
        resim: "",
        yayinDurumu: "YAYINDA",
      },
      image: null,
    };
  },
  created() {
    this.gelenYaziID = this.$route.params.id;

    this.kategoriListesiGetir();
    this.yaziListesiGetir(this.gelenYaziID);

    this.yeniYazi.tarih = new Date().toLocaleString();
  },
  mounted() {
    console.log("-----s-----", this.yeniYazi.baslik);
    this.yeniYazi.baslik = this.gelenYaziListesi.baslik;
  },
  computed: {},
  methods: {
    yaziListesiGetir(id) {
      axios
        .get("https://fordizayn-b99fd-default-rtdb.firebaseio.com/yazilar.json")
        .then((response) => {
          this.gelenYaziListesi = response.data;
          this.gelenYaziListesi = Object.values(this.gelenYaziListesi);

          console.log("gelen liste", this.gelenYaziListesi);
          console.log("gelen ID", this.gelenYaziID);

          this.gelenYaziListesi = this.gelenYaziListesi.find(
            (item) => item.yaziId === id
          );

          console.log("Bulunan liste", this.gelenYaziListesi);

          this.yeniYazi = this.gelenYaziListesi;
          this.gelenKategoriID = this.gelenYaziListesi.kategoriID;
          // console.log("kat ıd", this.gelenKategoriID);

          var newThis = this;

          setTimeout(function () {
            newThis.gelenKategoriAdi = newThis.kategoriler.find(
              (item) => item.uid === newThis.gelenKategoriID
            );
            console.log("kat Adı", newThis.gelenKategoriAdi);
            this.image = newThis.yeniYazi.resim;
          }, 1000);
        });
    },
    // Yazı Ekle
    yaziDuzenle() {
      axios
        .put(
          "https://fordizayn-b99fd-default-rtdb.firebaseio.com/yazilar/" +
            this.gelenYaziID +
            ".json",
          this.yeniYazi
        )
        .then((result) => {
          console.log(result);

          const savedPackageId = this.gelenYaziID;

          if (this.image) {
            console.log("Resim Seçildi", this.image);

            const imageName = this.image.name;
            const ext = imageName.slice(imageName.lastIndexOf("."));
            const imageRef = ref(
              storage,
              "cards/" + "image" + Date.now() + ext
            );

            uploadBytes(imageRef, this.image).then((snapshot) => {
              console.log("uploaded file!", snapshot);
              getDownloadURL(snapshot.ref).then((downloadUrl) => {
                console.log("file is located at ", downloadUrl);

                axios
                  .patch(
                    "https://fordizayn-b99fd-default-rtdb.firebaseio.com/yazilar/" +
                      savedPackageId +
                      ".json",
                    { resim: downloadUrl, yaziId: savedPackageId }
                  )
                  .then(() => {
                    this.isnotification = true;
                    this.alert = this.alertLight;
                    this.notification = "Kayıt İşleminiz Başarıyla Gerçekleşti";

                    this.yeniYazi.baslik = "";
                    this.yeniYazi.aciklama = "";
                    this.yeniYazi.icerik = "";
                    this.yeniYazi.resim = "";
                    this.yeniYazi.resim = "https://via.placeholder.com/150";

                    this.image = null;
                    this.selectImage = true;
                    this.removeImage = false;
                    this.$router.push("/controlPanel/yazilar");
                  });
              });
            });
          }

          this.$router.push("/controlPanel/yazilar");
        })
        .catch((err) => {
          console.log(err);
        });
    },

    chooseImage() {
      this.$refs.imageInput.click();
    },
    onImagePicked(event) {
      const files = event.target.files;
      const filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Uygun bir resim girin lütfen!");
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.yeniYazi.resim = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.image = files[0];
      this.selectImage = false;
      this.removeImage = true;
      this.isnotification = false;
    },

    removeImageFunc() {
      this.image = null;
      this.yeniYazi.resim = "https://via.placeholder.com/150";
      this.selectImage = true;
      this.removeImage = false;
    },

    // Yazı Listesi
    kategoriListesiGetir() {
      axios
        .get(
          "https://fordizayn-b99fd-default-rtdb.firebaseio.com/yazi_kategoriler.json"
        )
        .then((response) => {
          this.kategoriler = response.data;
          this.kategoriler = Object.values(this.kategoriler);
          console.log("Kategroler:");
        });
    },
  },
};
</script>


<style scoped>
.notification {
  margin-bottom: 15px;
  padding: 2px 2px;
  display: inline !important;
  width: 250px !important;
  line-height: 50px;
}
.btn-resim-yukle {
  width: 150px;
  margin-top: 10px;
}
.resim-yukleme-alani {
  display: flex;
  flex-direction: column;
}
.resim-yukleme-alani img {
  width: 150px;
}
.home {
  background-color: #f4f6fb;
  margin-left: -30px !important;
  width: calc(100% + 45px) !important;
}
.header {
  padding: 25px;
  height: 10px;
}
.header p {
  font-size: 24px;
}
.content {
  margin-top: 25px;
  height: 100vh;
}
.card {
  margin: 20px;
}
.card p {
  padding: 20px;
}
</style>
