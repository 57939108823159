<template>
  <div>
    <!-- <div class="overlay" v-if="!aboutInfo.resim">
      <div class="spinner-border" role="status"></div>
    </div> -->
    <div class="section1">
      <div class="header">
        <div class="container">
          <div class="row">
            <div class="col-md-3 col-sm-3 col-12 float-left">
              <p @click="panel" class="slogan">
                <span> {{ contactList.slogan }} </span>
              </p>
            </div>

            <div class="col-md-9 col-sm-9 col-12 float-left">
              <span class="contact">
                <div class="phone">
                  <i class="fas fa-phone-alt icon"></i> {{ contactList.phone1 }}
                </div>

                <div class="mail">
                  <i class="far fa-envelope-open icon"></i>
                  {{ contactList.mail }}
                </div>
              </span>
            </div>
          </div>
        </div>

        <!--Mobil Menü alanı-->
        <transition name="fade">
          <div class="mobil-menu-alani" v-if="mobilDurum">
            <div class="mobil-menu">
              <div class="mobil-line"></div>
              <ul>
                <li class="active"><a href="">ANASAYFA</a></li>
                <li><a href="#hakkimda-alani">HAKKIMDA</a></li>
                <li><a href="#yetkinliklerim-alani">YETKİNLİKLERİM</a></li>
                <li><a href="#calismalarim-alani">ÇALIŞMALARIM</a></li>
                <li><a href="#contact-alani">İLETİŞİM</a></li>
              </ul>
              <a class="cv-mobil" href="">CV İndir</a>
            </div>
          </div>
        </transition>
      </div>

      <!--slide alanı-->
      <div class="slide-section">
        <div class="container">
          <div class="row">
            <div class="col-md-2 col-sm-2 col-12 float-left">
              <div class="logo-section">
                <router-link to="/">
                  <img class="logo" src="../assets/images/logo.png"
                /></router-link>
                <i @click="openMobilMenu" class="fas fa-bars bar-icon1"></i>
              </div>
            </div>
            <div class="col-md-10 col-sm-10 col-12 float-left">
              <div class="menu-alani">
                <div class="pc-menu">
                  <ul>
                    <li class="active"><a href="">ANASAYFA</a></li>
                    <li><a href="#hakkimda-alani">HAKKIMDA</a></li>
                    <li><a href="#yetkinliklerim-alani">YETKİNLİKLERİM</a></li>
                    <li><a href="#calismalarim-alani">ÇALIŞMALARIM</a></li>
                    <li><a href="#contact-alani">İLETİŞİM</a></li>
                  </ul>
                  <a class="cv" href="">CV İndir</a>
                </div>
                <i @click="openMobilMenu" class="fas fa-bars bar-icon2"></i>
              </div>
            </div>
          </div>

          <div class="profil-box" v-if="aboutInfo.resim">
            <div class="row">
              <div class="col-md-4 col-12 float-left">
                <div class="profil-img">
                  <img :src="aboutInfo.resim" />
                </div>
              </div>
              <div class="col-md-8 col-12 float-left">
                <div class="profil-info">
                  <p class="baslik">{{ aboutInfo.baslik }}</p>
                  <p class="skill">{{ aboutInfo.aciklama }}</p>
                  <p class="content">
                    {{ aboutInfo.icerik }}
                  </p>

                  <div class="social-section">
                    <a target="_blank" :href="sosyalMedya.facebook"
                      ><i class="fab fa-facebook"></i
                    ></a>
                    <a target="_blank" :href="sosyalMedya.instagram"
                      ><i class="fab fa-instagram"></i
                    ></a>
                    <a target="_blank" :href="sosyalMedya.twitter"
                      ><i class="fab fa-twitter-square"></i
                    ></a>
                    <a target="_blank" :href="sosyalMedya.linkedIn"
                      ><i class="fab fa-linkedin"></i
                    ></a>
                  </div>

                  <div class="links">
                    <p class="hakkimda"><a href="#hakkimda-alani">HAKKIMDA</a></p>
                    <p class="calismalarim"><a href="#calismalarim-alani">ÇALIŞMALARIM</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      show: true,
      contactList: [],
      sosyalMedya: [],
      aboutInfo: [],
      mobilDurum: false,
    };
  },
  methods: {
    openMobilMenu() {
      this.mobilDurum = !this.mobilDurum;
    },
    panel() {
      this.$router.push("/login");
    },

    getContactInfo() {
      axios
        .get("https://fordizayn-b99fd-default-rtdb.firebaseio.com/contact.json")
        .then((response) => {
          this.contactList = Object.values(response.data)[0];
          this.sosyalMedya = response.data;
          this.sosyalMedya = Object.values(this.sosyalMedya);
          this.sosyalMedya = this.sosyalMedya[0];
        });
    },

    getHeaderInfo() {
      axios
        .get("https://fordizayn-b99fd-default-rtdb.firebaseio.com/yazilar.json")
        .then((response) => {
          this.aboutInfo = response.data;
          this.aboutInfo = Object.values(this.aboutInfo);
          this.aboutInfo = this.aboutInfo.find(
            (item) => item.yaziId === "-MxyPX5c-DCfG9fZQQwC"
          );
        });
    },
  },
  created() {
    this.getContactInfo();
    this.getHeaderInfo();
  },
};
</script>

<style scoped>
.spinner-border {
  margin-left: auto;
  display: block;
  margin-right: auto;
  margin-top: 200px;
  position: absolute;
  left: 50%;
}
.overlay {
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.4);
  position: relative;
}
.fade-enter-active {
  animation: fade-in 0.1s;
}
.fade-leave-active {
  animation: fade-in 0.1s reverse;
}
@keyframes fade-in {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(0.2);
  }
  40% {
    transform: scale(0.4);
  }
  50% {
    transform: scale(0.5);
  }
  80% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
.mobil-line {
  width: 120%;
  margin-top: -10px;
  margin-left: -10%;
  height: 5px;
  background-color: #0e243b;
}
.mobil-menu-alani {
  background-color: #0a304d;
  border-radius: 0;
  padding: 10px;
  margin-top: 95px;
  position: absolute;
  width: 100%;
  margin-left: -8px;
  z-index: 13213;
  border-bottom: 1px solid rgba(224, 224, 224, 0.6);
  box-shadow: 0 15px 25px;
}
.mobil-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mobil-menu ul {
  margin-left: -20px;
}
.mobil-menu ul li {
  padding-left: 0px;
  list-style: none;
  color: white;
  border-bottom: 1px solid rgba(224, 224, 224, 0.3);
  line-height: 38px;
}
.mobil-menu ul li a {
  text-transform: uppercase;
  color: white;
  text-decoration: none;
}
.logo-section {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}
.logo {
  width: 195px;
  height: 62px;
}
.menu-alani {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
.bar-icon2,
.bar-icon1 {
  margin-top: 10px;
  font-size: 33px;
  display: none;
  color: white;
}
.profil-info {
  width: 90%;
  padding-left: 30px;
}
.links {
  display: flex;
  margin-top: 20px;
}
.hakkimda  a{
  width: 180px;
  border-radius: 20px;

  background-color: rgb(251, 251, 252);
  color: #131212;
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  padding: 7px 20px;
}
.calismalarim a{
  width: 180px;
  border-radius: 20px;
  padding: 7px 10px;
  background-color: #f29100 !important;
  color: white;
  margin-left: 20px;
  font-weight: 600;
  text-align: center;
  font-size: 13px;
  padding: 7px 20px
}
.hakkimda a:hover,
.calismalarim a:hover {
  color: black;
  cursor: pointer;
  text-decoration: none;
  opacity: .9;
}
.baslik {
  color: white;
  font-size: 16px;
}
.skill {
  color: #d4820b;
  font-size: 36px;
  font-weight: 800;
  text-transform: uppercase;
}
.content {
  color: white;
  font-size: 16px;
  font-weight: 400;
}
.profil-box {
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
}
.profil-img {
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
}
.profil-img img {
  width: 270px;
  height: 270px;
  margin-right: 20px;
  object-fit: cover;
  border-radius: 50%;
  border: 9px solid rgba(131, 127, 127, 0.3);
}
.active a {
  color: #ffd31d !important;
  font-weight: 500;
}

.pc-menu ul li:hover {
  cursor: pointer;
  color: #ffd31d !important;
}
.pc-menu ul li a {
  font-size: 15px;
}
.pc-menu ul li a:hover {
  cursor: pointer;
  color: #ffd31d !important;
}
.pc-menu ul li::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 15px;
  background-color: #f0efeb !important;
  bottom: 4px;
  transition: all 0.4s;
  margin-left: 0px;
  transition: all 0.4s;
}

.pc-menu ul li:hover::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 20px;
  background-color: #ffd31d !important;
  bottom: 0px;
  transition: all 0.4s;
  margin-left: 0px;
}
.pc-menu ul li:first-child::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 15px;
  background-color: #ffd31d !important;
  bottom: 4px;
  transition: all 0.4s;
  margin-left: 0px;
  transition: all 0.4s;
}

.pc-menu {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
}
.cv {
  color: white;
  margin-left: 30px;
  margin-top: 12px;
  text-decoration: none;
  background-color: #f29100;
  height: 28px;
  margin-top: 0px;

  padding-top: 2px;
  min-width: 120px;
  border-radius: 20px;
  text-align: center;
  border-bottom: 1px solid rgba(240, 237, 237, 0.6);
  box-shadow: 1px 1px 1px #888888;
}
.cv:hover {
  background-color: #d1661e;
}
.cv-mobil {
  color: white;
  margin-left: 30px;
  margin-top: 12px;
  text-decoration: none;
  background-color: #f29100;
  height: 28px;
  padding-top: 3px;
  display: inline-block;
  border-radius: 20px;
  text-align: center;

  border-bottom: 1px solid rgba(240, 237, 237, 0.6);
}
.pc-menu ul {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 15%;
}
.pc-menu ul li {
  list-style: none;
  margin-left: 5%;
}

.pc-menu ul li a {
  color: white;
  list-style: none;
  margin-left: 7px;
  text-decoration: none;
  padding-left: 0px;
}

.social-section {
  line-height: 45px;
  display: flex;
  margin-right: 30px;
  justify-content: flex-start;
}

.social-section a i {
  font-size: 20px;
  margin-left: 5px;
  color: white;
  margin-right: 5px;
  transition: all 0.4s;
}
.social-section a i:hover {
  color: #ffd31d;
  box-shadow: 1px 1px 5px #888888;
  border-radius: 50%;
}

.bg-color {
  background-color: #03b8e1;
}
.section1 {
  position: relative;
  height: auto;
  background-image: url("../assets/images/wall5.png");
  background-position: center center;
  background-size: cover;
  padding-bottom: 100px;
}
.header {
  height: 45px;
  padding: 5px;
  background-color: rgba(2, 0, 11, 0.26);
  border-bottom: 1px solid rgba(224, 224, 224, 0.2);
}
.line1 {
  width: 100%;
  height: 1px;
  margin-top: 7px;
  background-color: rgba(224, 224, 224, 0.1);
  box-shadow: 1px 10px 115px #888888;
}
.slogan {
  margin-top: 8px;
}
.slogan span {
  color: white;
  font-size: 15px;
  font-weight: 400;
  padding-left: 10px;
}
.slogan::before {
  content: "";
  position: absolute;
  top: -5px;
  width: 1px;
  height: 43px;
  background-color: rgb(252, 246, 246, 0.2);
}
.icon {
  font-size: 15px;
  color: white;
  margin-right: 5px;
}
.contact {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: white;
}
.mail {
  margin-left: 50px;
}

@media (max-width: 1199px) {
  .pc-menu ul li {
    margin-left: 10px;
  }
}
@media (max-width: 1080px) {
  .container {
    max-width: 90% !important;
  }
  .profil-info {
    width: 90%;
    padding-left: 0px;
  }
  .pc-menu ul li {
    margin-left: 10px;
  }
  .pc-menu ul li a {
    font-size: 12px;
  }
  .contact {
    font-size: 12px;
  }
  .mail {
    font-size: 12px;
  }
  .slogan span {
    font-size: 12px;
  }

  .profil-img {
    margin-top: -30px;
    margin-right: 40px;
    display: flex;
    justify-content: center;
  }
  .profil-img img {
    width: 270px;
    height: 270px;
    margin-right: 0px;
  }
  .profil-info {
    margin-top: 30px;
    width: 100%;
    padding-left: 30px;
  }
}

@media (max-width: 915px) {
  .cv-mobil {
    height: 33px;
    font-size: 16px;
    padding: 5px 10px;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    min-width: 50%;
  }
  .bar-icon2 {
    display: block;
  }
  .pc-menu {
    display: none;
  }
  .profil-img {
    margin-right: 0px;
  }
}

@media (max-width: 768px) {
  .slogan::before {
    height: 0;
  }
}

@media (max-width: 600px) {
  .section1 {
    padding-bottom: 50px;
  }
  .hakkimda {
    width: 150px;
    height: 33px;
    padding: 7px 10px;
  }
  .calismalarim {
    width: 150px;
    height: 33px;
    padding: 7px 10px;
  }

  .bar-icon2 {
    display: none;
  }
  .bar-icon1 {
    display: block;
  }
  .header {
    height: 80px;
  }
  .slogan {
    text-align: center;
  }

  .contact {
    margin-top: 1px;
    justify-content: center;
  }
}

@media (max-width: 450px) {
  .profil-img img {
    width: 200px;
    height: 200px;
    margin-right: 0px;
  }
}
</style>
