<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModalCenter"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">! Uyarı</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          BU kaydı silmek istediğinizden emin misiniz?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Vazgeç
          </button>
          <button
            @click="kategoriSil"
            type="button"
            data-dismiss="modal"
            class="btn btn-primary"
          >
            Sil
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="home">
    <div class="header">
      <p>Yeni Yazı</p>
    </div>
    <div class="content">
      <div class="card">
        <p>Yazı Kategorileri</p>
        <div class="card-body">
          <div class="col-md-12 float-left">
            <div class="col-md-7 float-left">
              <div class="list-group">
                <button
                  type="button"
                  class="list-group-item list-group-item-action active"
                >
                  Kategori Listesi
                </button>

                <li
                  v-for="(item, key) in gelenKategoriListesi"
                  :key="key"
                  class="list-group-item list-group-item-action"
                >
                  <span class="kategori-list">
                    <p>
                      <i class="fas fa-angle-right"></i>{{ item.kategoriAdi }}
                    </p>
                    <div>
                      <a
                        class="btn btn-sm btn-primary"
                        @click="kategoriDuzenle1(key, item.kategoriAdi)"
                        >Düzenle</a
                      >

                      <button
                        type="button"
                        class="btn btn-sil btn-sm btn-danger"
                        data-toggle="modal"
                        data-target="#exampleModalCenter"
                        @click="kategoriSilOnay(key)"
                      >
                        Sil
                      </button>

                      <!-- <a
                        class="btn btn-sm btn-danger"
                        @click="kategoriSilOnay(item.uid)"
                        >Sil</a > -->
                    </div>
                  </span>
                </li>
              </div>
            </div>
            <div class="col-md-5 float-left">
              <div class="list-group">
                <button
                  type="button"
                  class="list-group-item list-group-item-action active"
                >
                  Kategori Ekle
                </button>

                <form>
                  <div class="form-group">
                    <input
                      v-model="yeniKategori.kategoriAdi"
                      type="text"
                      class="form-control mt-3"
                    />

                    <small v-if="ishata" id="emailHelp" class="form-text hata"
                      >* Zorunlu Alan
                    </small>
                  </div>

                  <button
                    type="submit"
                    v-show="btnShow"
                    @click="kategoriEkle"
                    class="btn btn-success"
                  >
                    Ekle
                  </button>
                  <button
                    v-show="!btnShow"
                    @click="kategoriDuzenle2"
                    type="submit"
                    class="btn btn-primary"
                  >
                    Düzenle
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { uuid } from "vue-uuid";

export default {
  data() {
    return {
      ishata: false,
      model: false,
      btnShow: true,
      gelenKategoriListesi: {},
      silinenKategoriID: "",
      duzenlenenKategoriID: "",
      duzenlenenKategori: [],
      yeniKategori: {
        uid: uuid.v1(),
        kategoriAdi: "",
      },
    };
  },

  created() {
    this.model = true;
    this.kategoriListesiGetir();
  },
  methods: {
    //Kategori Düzenle
    kategoriDuzenle1(id, name) {
      this.duzenlenenKategoriID = id;
      this.yeniKategori.kategoriAdi = name;

      // this.gelenKategoriListesi = Object.values(this.gelenKategoriListesi);
      // console.log(this.gelenKategoriListesi);
      // this.duzenlenenKategoriID = id;
      // this.duzenlenenKategori = this.gelenKategoriListesi.find(
      //   (item) => item.id === id
      // );
      // this.yeniKategori.kategoriAdi = this.duzenlenenKategori.kategoriAdi;
      this.btnShow = false;

      console.log(this.duzenlenenKategoriID);
      console.log(this.yeniKategori.kategoriAdi);
    },
    kategoriDuzenle2() {
      this.btnShow = true;

      axios
        .patch(
          "https://fordizayn-b99fd-default-rtdb.firebaseio.com/yazi_kategoriler/" +
            this.duzenlenenKategoriID +
            ".json",
          {
            kategoriAdi: this.yeniKategori.kategoriAdi,
          }
        )
        .then(() => {
          this.kategoriListesiGetir();
          this.yeniKategori = "";
        });
    },

    //Kategori Sil Onay Penceresi
    kategoriSilOnay(id) {
      console.log("silinen ıd", id);
      this.silinenKategoriID = id;
    },

    //Kategori Sil
    kategoriSil() {
      axios
        .delete(
          "https://fordizayn-b99fd-default-rtdb.firebaseio.com/yazi_kategoriler/" +
            this.silinenKategoriID +
            ".json"
        )
        .then(() => {
          this.kategoriListesiGetir();
          this.model = false;
        });
    },

    //Kategori Ekle
    kategoriEkle() {
      this.ishata = false;
      if (this.yeniKategori.kategoriAdi == "") {
        this.ishata = true;
      } else {
        this.yeniKategori.uid = uuid.v1();
        this.ishata = false;
        axios
          .post(
            "https://fordizayn-b99fd-default-rtdb.firebaseio.com/yazi_kategoriler.json",
            this.yeniKategori
          )
          .then(() => {
            this.yeniKategori.kategoriAdi = "";
            this.kategoriListesiGetir();
            console.log("Kayıt Başarılı");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    //Kategori Lİstesi Getir
    kategoriListesiGetir() {
      axios
        .get(
          "https://fordizayn-b99fd-default-rtdb.firebaseio.com/yazi_kategoriler.json"
        )
        .then((response) => {
          this.gelenKategoriListesi = response.data;
          console.log(this.gelenKategoriListesi);
        });
    },
  },
};
</script>

<style scoped>
.hata {
  color: red;
}
.kategori-list {
  margin-top: -5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 45px;
}
.kategori-list i {
  margin-right: 5px;
}
.kategori-list a {
  margin-top: 15px;
  margin-left: 5px;
  color: white !important;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}
.kategori-list a:hover {
  color: aliceblue;
  cursor: pointer;
}
.modal-backdrop.show {
  opacity: 0 !important;
}
.btn-resim-yukle {
  width: 150px;
  margin-top: 10px;
}
.resim-yukleme-alani {
  display: flex;
  flex-direction: column;
}
.resim-yukleme-alani img {
  width: 150px;
}
.home {
  background-color: #f4f6fb;
  margin-left: -30px !important;
  width: calc(100% + 45px) !important;
}
.header {
  padding: 25px;
  height: 10px;
}
.header p {
  font-size: 24px;
}
.content {
  margin-top: 25px;
  height: 100vh;
}
.card {
  margin: 20px;
}
.card p {
  padding: 20px;
}
.btn {
  margin-right: 20px;
}

.btn-sil {
  margin-top: 15px;
  width: 50px;
}
</style>
