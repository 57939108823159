<template>
  <div>
    <div id="contact-alani" class="hakkimda-alani">
      <div class="container">
        <p class="title">Bi Talebiniz mi var?</p>
        <div class="row">
          <div class="col-md-5">
            <div class="box">
              <img src="../assets/images/img1.png" />
            </div>
          </div>
          <div class="col-md-7">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label"
                    >Adınız ve Soyadınız
                  </label>
                  <input
                    v-model="payload.name"
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label"
                    >İletişim Bilgileriniz
                  </label>
                  <input
                    v-model="payload.contact"
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                  />
                </div>
              </div>

              <div class="col-md-12">
                <div class="mb-3">
                  <label for="exampleFormControlTextarea1" class="form-label"
                    >Mesajınız</label
                  >
                  <textarea
                    v-model="payload.message"
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                  ></textarea>
                </div>
                <button
                  type="button"
                  @click="sendMessage"
                  class="btn btn-warning"
                >
                  Gönder
                </button>
                <p class="success" v-if="success">
                  Mesajınız başarıyla gönderildi. O iş bizde
                </p>
                <p class="error" v-if="error">Sanırım eksik bi şeyler var</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      error: false,
      success: false,
      projectList: {},
      allList: {},
      payload: {
        name: "",
        contact: "",
        message: "",
      },
    };
  },
  created() {
    this.getProjects();
  },

  methods: {
    getProjects() {
      axios
        .get("https://fordizayn-b99fd-default-rtdb.firebaseio.com/yazilar.json")
        .then((response) => {
          this.allList = response.data;
          this.allList = Object.values(this.allList);
          this.projectList = this.allList.filter(
            (item) => item.kategoriID === "450c35f0-0b2f-11ed-bbe2-e957265a19fb"
          );

          this.projectList.sort(function (a, b) {
            return a.sira > b.aciklama ? -1 : 1;
          });
        });
    },

    sendMessage() {
      if (
        this.payload.name == "" ||
        this.payload.message == "" ||
        this.payload.contact == ""
      ) {
        this.message = "Sanırım eksik bi şeyler var.";
        this.error = true;
        this.success = false;
      } else {
        axios
          .post(
            "https://fordizayn-b99fd-default-rtdb.firebaseio.com/mesajlar.json",
            this.payload
          )
          .then((res) => {
            console.log("res:", res);
            this.success = true;
            this.error = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped>
.title{
  display: block;
  text-align: center;
  color: orange;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 60px;
}
.error {
  color: #d10c26;
  margin-top: 20px;
}
.box img {
  width: 300px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 2px -1px,
    rgba(0, 0, 0, 0.02) 0px 2px 4px -1px;
}
.success {
  color: #ffd31d;
  margin-top: 20px;
}
.info-section p,
.form-label {
  color: white;
}
.hakkimda-alani {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #343436;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.languages {
  margin-top: 10px;
  background-color: #97cac6;
  padding: 5px 15px;
  font-size: 12px;
  display: block;
  width: 190px;
  border-radius: 3px;
}
.card {
  margin: 30px;
  width: 100%;
  transition: all 0.5s;
  background-color: rgba(255, 255, 255, 0.95);
}
.card:hover {
  margin-top: 26px;
  cursor: pointer;
}
.section-baslik {
  font-size: 20px;
  font-weight: 600;
  background-color: #ffd31d;
  padding: 7px 20px;
  box-shadow: 1px 1px 5px #888888;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  margin-top: 30px;
  width: 30%;
  line-height: 30px;
  text-align: center;
}

.calismalarim-alani {
  width: 100%;
  height: auto;
  padding: 30px 5px;
  padding-bottom: 50px;
}
.calismalarim-box {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 80px;
  border-bottom: 1px solid rgba(224, 224, 224, 0.1);
  padding: 1px;
}
.img {
  position: relative;
}
.img img {
  width: 100%;
  height: 220px;
  object-fit: cover;
}
.info {
  background-color: white;
  position: relative;
  width: 100%;
  height: 300px;
}

.baslik {
  color: rgb(197, 127, 47);
  font-size: 20px;
  font-weight: 600;
  padding-left: 10px;
  background-color: white;
}
.aciklama {
  color: rgb(236, 232, 232);
  font-size: 16px;
  margin-top: 10px;
  padding-left: 10px;
}
</style>
