import { createRouter, createWebHistory } from "vue-router";

import Home from "../pages/home.vue";
import login from "../pages/login.vue";
import controlPanelLayout from "../layouts/controlPanelLayout";
import controlPanelhome from "../controlPanel/home";
import yazilar from "../controlPanel/yazilar";
import yazi_ekle from "../controlPanel/yazi_ekle";
import yazi_kategoriler from "../controlPanel/yazi_kategoriler";
import yazi_duzenle from "../controlPanel/yazi_duzenle";
import test from "../controlPanel/test";

const routes = [
  { path: "/", component: Home },
  { path: "/home", component: Home },
  { path: "/login", component: login },


  {
    path: "/controlPanel",
    name: "controlPanelLayout",
    component: controlPanelLayout,

    beforeEnter(to, from, next) {
      if (localStorage.getItem("token")) {
        next();
      } else {
        next("/login");
      }
    },

    children: [
      {
        path: "/controlPanel/home",
        name: "controlPanelhome",
        component: controlPanelhome,
      },

      {
        path: "/controlPanel/yazilar",
        name: "yazilar",
        component: yazilar,
      },

      {
        path: "/controlPanel/yazi_ekle",
        name: "yazi_ekle",
        component: yazi_ekle,
      },

      {
        path: "/controlPanel/yazi_kategoriler",
        name: "yazi_kategoriler",
        component: yazi_kategoriler,
      },

      {
        path: "/controlPanel/yazi_duzenle/:id",
        name: "yazi_duzenle",
        component: yazi_duzenle,
      },
      {
        path: "/controlPanel/test",
        name: "test",
        component: test,
      },
    ],
  },

  // { path: "/controlPanel/home", component: controlPanelhome },
];

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
});

export default router;
