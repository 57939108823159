<template>
  <div>
    <QuillEditor v-model="content" theme="snow" />
    <!-- <p v-mode="content"></p> -->
    <p @click="goster">Göster</p>
    <p>{{ content }}</p>
    <div v-html="content"></div>
  </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  components: {
    QuillEditor,
  },

  data() {
    return {
      content: "<h1>Some initial content</h1>",
    };
  },
  methods: {
    goster() {
      console.log(this.content.ops);
      console.log(this.content.ops);
    },
  },
};
</script>
