<template>
  <div @mousemove="deleteCounter" class="layout">
    <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12 float-left box">
      <controlMenu></controlMenu>
    </div>

    <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8 col-12 float-left box">
      <controlHeader></controlHeader>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import controlHeader from "../controlPanel/header.vue";
import controlMenu from "../controlPanel/menu.vue";
export default {
  data() {
    return {
      sayac: 0,
    };
  },
  components: {
    controlHeader,
    controlMenu,
  },
  methods: {
    deleteCounter() {
      this.sayac = 0;
    },
  },
  created() {
    var newThis = this;
    setInterval(function () {
      newThis.sayac++;
      if (newThis.sayac == 600) {
        localStorage.clear();
        newThis.$router.push("/login");
      }
    }, 1000);
  },
};
</script>

<style scoped></style>
