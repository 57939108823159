<template>
  <div>
    <div class="loginPage">
      <div class="loginBox">
        <img src="../assets/images/logo4.png" class="user" />
        <h2>fordizayn.com</h2>
        <h3>YÖNETİM PANELİ</h3>

        <input
          type="text"
          v-model="userName"
          class="mailKutu"
          placeholder="username"
        />

        <input
          type="password"
          v-model="userPassword"
          class="mailKutu"
          placeholder="password"
        />

        <p v-if="hataMesaj" class="hataMesaji">{{ hataMesaji }}</p>

        <div class="loginFooter">
          <a @click="login" class="btn btn-warning">Giriş</a>
          <!-- <a @click="register" class="btn btn-warning">Kayıt Ol</a> -->

          <router-link class="btn-anasayfa" to="/">Anasayfa</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import axios from "axios";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      userList: [],
      hataMesaji: "",
      user: [],
      hataMesaj: "",
      userName: "",
      userPassword: "",
      userID: uuid.v1(),
    };
  },

  validations: {
    userName: {
      required,
    },
    userPassword: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(10),
    },
  },
  // computed: {
  //   getusername() {
  //     return this.$store.state.username;
  //     // return this.$store.getters.doubleCounter;
  //   },
  // },

  created() {},
  mounted() {
    console.log(this.$store.state.username);
  },
  methods: {
    register() {
      axios
        .post(
          "https://fordizayn-b99fd-default-rtdb.firebaseio.com/users.json",
          {
            userImage: "https://inmortaltechnologies.com/images/page/user.png",
            userName: this.userName,
            userPassword: this.userPassword,
            userID: this.userID,
          }
        )
        .then((response) => {
          console.log(response);
        });
    },

    login() {
      if (this.userName == "" || this.userPassword == "") {
        this.hataMesaj = true;
        this.hataMesaji = "Lütfen İlgili Alanları Doldurunuz";
      } else {
        axios
          .get("https://fordizayn-b99fd-default-rtdb.firebaseio.com/users.json")
          .then((response) => {
            this.userList = response.data;

            this.userList = Object.values(this.userList);
            console.log("user list", this.userList);
            this.user = this.userList.find(
              (item) => item.userName === this.userName
            );

            if (this.user.userPassword == this.userPassword) {
              console.log(this.userName);
              this.$store.commit("usernameMutations", this.userName);

              localStorage.setItem("token", this.userName);
              this.$router.push("/controlPanel/home");
            }
          })
          .catch(() => {
            this.hataMesaj = true;
            this.hataMesaji = "Kayıt Bulunamadı";
          });
      }
    },
  },
};
</script>

<style scoped>
.notification {
  color: rgb(218, 87, 12) !important;
  font-size: 12px;
  margin-left: 20px;
}
.btn {
  margin-right: 10px;
  color: aliceblue !important;
  border: 1px solid white;
  border-radius: 20px;
}
.btn:hover {
  cursor: pointer;
}
.btn-anasayfa {
  margin-right: 10px;
  color: orange !important;
  border-radius: 20px;
  text-align: center;
}

.btn2 {
  margin-right: 10px;
  color: aliceblue !important;
  background-color: orange;
}
.loginPage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: url("../assets/images/wall3.png");

  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.loginBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: auto;
  box-sizing: border-box;
  background: rgba(26, 107, 145, 0.574);
  padding: 50px;
  border-radius: 10px;
}
.loginBox img {
  width: 100px;
  height: 100px;
  background-color: white;
}

.user {
  border-radius: 50%;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  border: 1px solid white;
}

h2 {
  padding-top: 10px;
  margin: 0;
  color: whitesmoke;
  text-align: center;
  font-size: 26px;
  padding: 0;
  list-style: none;
  font-size: 15px;
  font-weight: bold;
  font-family: "Nunito", sans-serif !important;
}
h3 {
  margin: 0;
  color: papayawhip;
  text-align: center;
  font-size: 15px;
  margin-bottom: 10px;
}
.loginBox p {
  color: rgb(252, 251, 250);
}

.txtMail {
  margin-bottom: -30px;
}
.mailKutu:hover {
  background-color: orangered;
}

.hataMesaji {
  color: orange !important;
  margin-top: 10px;
  padding-top: 5px;
  font-size: 15px;
  display: block;
  text-align: center;
}

.loginBox input[type="text"],
.loginBox input[type="password"] {
  border: none;
  background-color: transparent;
  outline: none;
  height: 35px;
  color: #fff;
  margin-bottom: 5px;
  border-radius: 30px;
  padding-left: 20px;
  font-size: 16px;
  border-bottom: 1px solid #fff;
  width: 100%;
}

.loginBox input[type="submit"] {
  width: 100%;
  height: 30px;
  border: none;
  color: white;
  background-color: coral;
  margin-bottom: 10px;
  margin-top: 20px;
  border-radius: 20px;
}
.loginBox input[type="submit"]:hover {
  background-color: aliceblue;
  color: darkgray;
}
::placeholder {
  color: rgb(252, 250, 249);
  font-size: 16px;
}
.loginBox h2 {
  margin-top: 30px;
  font-weight: 400;
}

.loginBox h3 {
  font-size: 20px;
  color: aliceblue;
  margin-top: 5px;
  margin-bottom: 30px;
}
a {
  color: antiquewhite;
  margin-top: 15px;
  font-size: 15px;
}

.loginFooter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}
.sifremiUnuttum {
  text-align: center;
}
</style>
