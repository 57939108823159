<template>
  <div>
    <div class="footer-section">
      <div class="container">
        <div class="contact-alani">
          <div class="row">
            <div class="col-md-3 col-12">
              <div class="logo-alani">
                <img class="logo" src="../assets/images/logo.png" />
              </div>
            </div>
            <div class="col-md-9 col-12">
              <div class="contact">
                <p>İletişim Bilgilerimiz:</p>
                <p>0553 759 1442</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>

    <div class="footer-bottom">
      <div class="container">
        <router-link class="link" to="./login"> fordizayn.com</router-link>
        <span>
          <a href="" class="link">Web Geliştirme:fordizayn</a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
//import axios from "axios";

export default {
  data() {
    return {};
  },
  created() {},
  computed: {},

  methods: {},
};
</script>

<style scoped>
.contact-alani row {
  display: flex;
  justify-content: space-between;
}
.contact {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.contact p {
  color: white;
  margin-left: 20px;
}
.contact p:first-child {
  font-size: 16px;
  margin-top: 15px;
}
.contact p:nth-child(2) {
  color: white;
  padding: 7px 10px;
  border-radius: 25px;
  width: 200px;
  text-align: center;
  font-weight: 600;
  margin-top: 5px;
  font-size: 20px;
  background-color: orange;
}
.line {
  width: 100%;
  height: 10px;
  background-color: #25454b;
  position: relative;
  margin-top: -10px;
}
.hr {
  width: 80%;
  color: rgb(17, 16, 14);
  margin: 20px;
  border-color: rgb(122, 118, 118);
  margin-left: 35px;
}
.hr2 {
  margin-top: 15px;
  border-color: rgb(122, 118, 118);
}
.social-section {
  margin-left: 23px;
}
.social-section p {
  font-size: 20px;
  margin-left: 10px;
  color: white;
}
.social-section a i {
  font-size: 23px;
  margin-left: 10px;
  color: white;
}

.footer-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.icon {
  margin-right: 10px;
}
.footer-section {
  width: 100%;
  padding-top: 15px;
  background-image: url("../assets/images/wall5.png");
  background-position: center center;
  background-size: cover;
  background-color: #112031;
  height: auto;
}
.logo-section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  text-align: center;
}
.logo {
  width: 200px;
}
.menu {
  width: 100%;
  position: relative;
}

.footer-title ul {
  display: flex;
  margin-top: 30px;
  width: 600px;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  margin-left: -20px;
}
.menu ul li {
  list-style: none;
  color: azure;
  font-size: 15px;
  font-weight: bold;
}

.footer-bottom {
  position: relative;
  top: 0;
  left: 0;

  padding: 10px 20px;
  background-color: #0a2d4b;
  border-top: 1px solid rgba(224, 224, 224, 0.6);
}
.footer-bottom .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.link {
  color: rgb(170, 165, 165);
  font-size: 12px;
  text-decoration: none;
  font-weight: 500;
}
.footer-link {
  color: aliceblue;
  position: relative;
  display: block;
  width: 100%;
  text-decoration: none;
  margin-top: 5px;
  cursor: pointer;
  transition: all 0.6s;
}
.footer-link:hover {
  color: bisque;
  margin-left: 2px;
  text-decoration: solid;
}
.footer-title {
  color: aliceblue;
  font-size: 15px;
  margin: 10px;
  padding: 10px;
  padding-left: 50px;
  font-weight: bold;
  position: relative;
  display: block;
  width: 100%;
}
.footer-title::before {
  content: " ";
  position: absolute;
  top: 12px;
  left: 28px;
  width: 2px;
  height: 18px;
  background-color: rgb(177, 174, 169);
}
.footer-info {
  margin-bottom: 10px;
  color: white;
}

@media (max-width: 1025px) {
  .link {
    font-size: 9px;
  }
  .contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .logo-alani {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
  }
}

@media (max-width: 850px) {
  .contact p:nth-child(2) {
    padding: 5px 15px;
    border-radius: 15px;
    width: 150px;
    height: 33px;
    margin-top: 12px;

    font-size: 16px;
  }
}
</style>
